<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-container">
      <div class="auth-form">
        <ShowTitle text="忘記密碼" type="title" />
        <i
          @click="$router.push({ path: '/user/signin' })"
          class="
            auth-close-btn
            pointer
            el-icon-close
            basic-hover
            ease-in-out-03s
            font-gray
          "
        ></i>
        <el-form>
          <el-form-item>
            <el-input
              style="width: 260px"
              placeholder="電子郵件"
              type="email"
              v-model="dataObj.email"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="form-button-container">
          <el-button
            class="ease-in-out-03s basic-submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
          >
            寄送新的密碼
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userPasswordReset } from "@/api/user";

export default {
  mixins: [mainCommon],
  data() {
    return {
      submitLoading: false,
      dataObj: {
        email: null,
      },
    };
  },
  created() {
    this.HandleAuthorized();
  },
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      userPasswordReset(this.dataObj)
        .then((res) => {
          this.pushNotification(res.message);
          setTimeout(() => {
            this.$router.push({ path: "/user/signin" });
          }, 500);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input .el-input__inner {
  padding: 0 15px;
}
</style>